var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.totalCount > 0)?_c('div',{class:_vm.user
      ? 'custom-pagination-container mt-2 mr-0 ml-0'
      : 'custom-pagination-container ma-0'},[_c('div',{class:_vm.$vuetify.breakpoint.smAndUp ? 'footer-pagination' : '',attrs:{"cols":"12"}},[(_vm.showDataDetails)?_c('div',{class:_vm.user
          ? 'user-footer-pagination-para pagination-enteries font-12 ma-0'
          : 'footer-pagination-para pagination-enteries font-12 ma-0 ml-2'},[(_vm.pagination > 0)?_c('span',{staticClass:"d-flex align-baseline justify-center fw-500"},[_vm._v(" Showing "),_c('v-select',{staticClass:"custom-pagination bg-color mx-3 width-removed",attrs:{"items":_vm.items,"dense":"","filled":""},on:{"change":function($event){return _vm.selectedRecords()}},model:{value:(_vm.numberOfRecords),callback:function ($$v) {_vm.numberOfRecords=$$v},expression:"numberOfRecords"}}),_vm._v(" of "),_c('span',{staticStyle:{"color":"var(--v-lightPurple-base) !important"}},[_vm._v(" "+_vm._s(_vm.pagination)+" ")]),_vm._v(" entries ")],1):_c('span',[_vm._v(" No Records to show ")])]):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-spacer'):_vm._e(),_c('div',{staticClass:"footer-pagination-items ma-0 pa-0",class:[
        _vm.user ? 'user-footer-pagination-items' : _vm.position,
        _vm.$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center',
      ]},[_c('v-btn',{staticClass:"prev-btn",attrs:{"disabled":_vm.page == 1,"elevation":"0"},on:{"click":_vm.prevPageButton}},[_c('v-icon',{staticClass:"pagination-btn-icon fs-22"},[_vm._v("mdi-chevron-left")])],1),_c('v-pagination',{staticClass:"custom-pagination",attrs:{"value":_vm.page,"total-visible":7,"length":_vm.xCount ? _vm.xCount : _vm.pageCounter,"color":"#000"},on:{"input":function($event){return _vm.$emit('page-number-changed', $event)},"next":function($event){return _vm.$emit('next-page-changed')},"previous":function($event){return _vm.$emit('previous-page-changed')}}}),_c('v-btn',{staticClass:"next-btn",attrs:{"disabled":_vm.totalCount == 0 || _vm.page === _vm.xCount,"elevation":"0"},on:{"click":_vm.nextPageButton}},[_c('v-icon',{staticClass:"pagination-btn-icon font-15"},[_vm._v("mdi-chevron-right")])],1)],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }