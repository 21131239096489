<template>
  <v-dialog
    v-model="dialog"
    persistent
    height="387"
    max-width="470"
    class="emp-dialog"
  >
    <v-card>
      <v-card-title
        style="letter-spacing: -0.3px"
        class="dialogTitle--text fs-18 font-weight-medium f-inter justify-space-between"
      >
        Create Employee
        <span
          class="material-icons custom-close-icon mt-3"
          @click="closeDialog()"
        >
          close
        </span>
      </v-card-title>
      <v-divider style="border: 0.3px solid #e8ebf6"></v-divider>
      <v-form ref="newEmployee" justify="center">
        <v-row class="mt-5 pl-3 pr-3">
          <v-col
            v-for="(input, index) in employeeDialogFields"
            :key="index"
            :md="input.col_md ?? 12"
            :cols="input.cols ?? 6"
            class="pt-0 pb-0"
          >
            <v-text-field
              v-if="input.type != 'select'"
              filled
              :label="input.label"
              :placeholder="input.placeholder"
              :type="input.type"
              background-color="inputBackground"
              flat
              autocomplete="off"
              class="text-no-wrap rounded-md custom"
              :append-icon="
                input.name == 'password' || input.name == 'confirmPassword'
                  ? input.showIcon
                  : ''
              "
              v-on:keyup.enter="createEmployee()"
              @click:append="
                () =>
                  input.type == 'password'
                    ? ((input.type = 'text'), (input.showIcon = 'visibility'))
                    : ((input.type = 'password'),
                      (input.showIcon = 'visibility_off'))
              "
              v-model="input.model"
              :rules="
                input.name == 'email'
                  ? emailRules
                  : input.name == 'confirmPassword'
                  ? confirmPasswordRules
                  : input.name == 'password'
                  ? passwordLengthRules
                  : requiredFieldRules
              "
            ></v-text-field>

            <v-select
              background-color="inputBackground"
              class="text-no-wrap rounded-md custom select-chip test"
              filled
              v-if="input.type == 'select'"
              :items="workspaces"
              label="Select Organisation*"
              dense
              multiple
              hide-details
              clearable
              v-model="input.model"
              item-text="workspaceName"
              item-value="_id"
              :rules="requiredFieldRulesForWorkspaces"
              :menu-props="{
                closeOnContentClick: false,
                bottom: true,
                offsetY: true,
              }"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2">
                  <span class="font-weight-medium">
                    {{ item.workspaceName }}
                  </span>
                </v-chip>
                <v-chip v-else-if="index === 2">
                  <span class="font-weight-medium">
                    (+{{ input.model.length - 2 }} others)
                  </span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions class="mt-5 pb-5">
        <v-btn
          block
          class="primary--text lightBackground fs-13 f-inter create-btn"
          style="text-transform: none !important"
          @click="createEmployee()"
          :loading="loader"
        >
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import apiService from "../../../services/Api";
export default {
  data() {
    return {
      loader: false,
      dialog: false,
      employeeDialogFields: [
        {
          name: "fullName",
          label: "Full Name*",
          placeholder: "Enter Full Name",
          type: "text",
          cols: 12,
          col_md: 12,
          model: "",
        },
        {
          name: "email",
          label: "Email*",
          placeholder: "Enter Your Email Here",
          type: "email",
          cols: 12,
          col_md: 12,
          model: "",
        },
        {
          name: "password",
          label: "Password*",
          placeholder: "Enter Password",
          type: "password",
          cols: 12,
          col_md: 6,
          showIcon: "visibility_off",
          model: "",
        },
        {
          name: "confirmPassword",
          label: "Confirm Password*",
          placeholder: "Enter Password",
          type: "password",
          cols: 12,
          col_md: 6,
          showIcon: "visibility_off",
          model: "",
        },
        {
          name: "organisations",
          label: "Select Organisation*",
          placeholder: "Select Organisation",
          type: "select",
          cols: 12,
          col_md: 12,
          model: [],
        },
      ],
      requiredFieldRulesForWorkspaces: [
        (v) => v.length > 0 || "At least one organisation is required",
      ],
      emailRules: [
        (v) => !!v || "Field is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
          "E-mail must be valid",
      ],
      passwordLengthRules: [
        (v) => !!v || "Field is required",
        (v) =>
          (v && v.length >= 6) ||
          "Length must be greater than or equal to 6 digits",
        (v) =>
          (v && v.length <= 15) ||
          "Length must be less than or equal to 15 digits",
      ],
      confirmPasswordRules: [
        (v) => !!v || "Field is required",
        (v) =>
          v === this.employeeDialogFields[3].model || "Password does not match",
        (v) =>
          (v && v.length >= 6) ||
          "Length must be greater than or equal to 6 digits",
        (v) =>
          (v && v.length <= 15) ||
          "Length must be less than or equal to 15 digits",
      ],
      requiredFieldRules: [(v) => !!v || "Field is required"],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser",
      workspaces: "auth/getUserWorkSpaces",
      currentWorkspace: "auth/getCurrentWorkspace",
      paginate: "ticket/getPaginate",
    }),
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.$refs.newEmployee.reset();
      this.dialog = false;
    },

    // Method to create new employee
    createEmployee() {
      if (!this.$refs.newEmployee.validate()) {
        return this.$showNotificationBar(
          "error",
          "Please fill all the required fields"
        );
      }
      if (
        this.employeeDialogFields[2].model != this.employeeDialogFields[3].model
      ) {
        return this.$showNotificationBar(
          "error",
          "The password confirmation does not match"
        );
      }

      this.loader = true;
      this.$store
        .dispatch("user/createEmployee", {
          name: this.employeeDialogFields[0].model,
          email: this.employeeDialogFields[1].model,
          password: this.employeeDialogFields[2].model,
          workspaces: this.employeeDialogFields[4].model,
          role: "employee",
        })
        .then((res) => {
          this.loader = false;
          this.dialog = false;
          this.$refs.newEmployee.reset();
          this.$showNotificationBar("success", "Employee created successfully");
          this.$emit("get-employees");
          this.$store.commit("ticket/setPaginate", {
            itemsPerPage: 10,
            page: 1,
            lastPage:
              this.paginate.total + 1 < 10
                ? 1
                : Math.ceil((this.paginate.total + 1) / 10),
            total: this.paginate.total + 1,
          });
        })
        .catch((err) => {
          this.$showNotificationBar("error", err?.response?.data?.message);
          console.log(err, "create emp err");
          this.loader = false;
          // this.dialog = false;
        });
    },
  },
};
</script>

<style scoped>
:deep
  .v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(
    .v-text-field--outlined
  )
  .v-select__selections {
  padding-top: 30px !important;
  padding-bottom: 10px !important;
}
.create-btn {
  width: 74px !important;
  height: 38px !important;
  background: #f4f1fe;
  border-radius: 8px;
  font-weight: 600 !important;
  letter-spacing: -0.3px;
}
:deep .v-text-field__details {
  white-space: break-spaces !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  border-radius: 10px !important;
}
:deep .select-chip .v-chip {
  background: #f4f1fe !important;
  color: #6634e9 !important;
  padding: 10px !important;
  border-radius: 14px !important;
}
:deep .v-btn--is-elevated {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 0%) !important;
}
.emp-dialog {
  background-color: #fff !important;
  border-radius: 10px !important;
  min-height: 387px !important;
}
:deep .custom-placeholder input::placeholder {
  color: black !important;
}

.v-input__slot {
  min-height: 37px !important;
}
:deep .v-input--selection-controls {
  margin-top: 0px !important;
}
:deep .v-input__icon {
  width: 14px !important;
  min-width: 14px !important;
}
:deep .v-icon {
  font-size: 14px;
  color: black;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(232, 235, 244, 1);
}
.v-application .mt-15 {
  margin-top: 16px !important;
}
</style>
