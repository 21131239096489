var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"emp-dialog",attrs:{"persistent":"","height":"387","max-width":"470"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"dialogTitle--text fs-18 font-weight-medium f-inter justify-space-between",staticStyle:{"letter-spacing":"-0.3px"}},[_vm._v(" Create Employee "),_c('span',{staticClass:"material-icons custom-close-icon mt-3",on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" close ")])]),_c('v-divider',{staticStyle:{"border":"0.3px solid #e8ebf6"}}),_c('v-form',{ref:"newEmployee",attrs:{"justify":"center"}},[_c('v-row',{staticClass:"mt-5 pl-3 pr-3"},_vm._l((_vm.employeeDialogFields),function(input,index){return _c('v-col',{key:index,staticClass:"pt-0 pb-0",attrs:{"md":input.col_md ?? 12,"cols":input.cols ?? 6}},[(input.type != 'select')?_c('v-text-field',{staticClass:"text-no-wrap rounded-md custom",attrs:{"filled":"","label":input.label,"placeholder":input.placeholder,"type":input.type,"background-color":"inputBackground","flat":"","autocomplete":"off","append-icon":input.name == 'password' || input.name == 'confirmPassword'
                ? input.showIcon
                : '',"rules":input.name == 'email'
                ? _vm.emailRules
                : input.name == 'confirmPassword'
                ? _vm.confirmPasswordRules
                : input.name == 'password'
                ? _vm.passwordLengthRules
                : _vm.requiredFieldRules},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.createEmployee()},"click:append":() =>
                input.type == 'password'
                  ? ((input.type = 'text'), (input.showIcon = 'visibility'))
                  : ((input.type = 'password'),
                    (input.showIcon = 'visibility_off'))},model:{value:(input.model),callback:function ($$v) {_vm.$set(input, "model", $$v)},expression:"input.model"}}):_vm._e(),(input.type == 'select')?_c('v-select',{staticClass:"text-no-wrap rounded-md custom select-chip test",attrs:{"background-color":"inputBackground","filled":"","items":_vm.workspaces,"label":"Select Organisation*","dense":"","multiple":"","hide-details":"","clearable":"","item-text":"workspaceName","item-value":"_id","rules":_vm.requiredFieldRulesForWorkspaces,"menu-props":{
              closeOnContentClick: false,
              bottom: true,
              offsetY: true,
            }},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index < 2)?_c('v-chip',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.workspaceName)+" ")])]):(index === 2)?_c('v-chip',[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" (+"+_vm._s(input.model.length - 2)+" others) ")])]):_vm._e()]}}],null,true),model:{value:(input.model),callback:function ($$v) {_vm.$set(input, "model", $$v)},expression:"input.model"}}):_vm._e()],1)}),1)],1),_c('v-card-actions',{staticClass:"mt-5 pb-5"},[_c('v-btn',{staticClass:"primary--text lightBackground fs-13 f-inter create-btn",staticStyle:{"text-transform":"none !important"},attrs:{"block":"","loading":_vm.loader},on:{"click":function($event){return _vm.createEmployee()}}},[_vm._v(" Save Changes ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }